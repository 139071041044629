
import React from 'react';
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline';
import {
    FaBold,
    FaItalic, 
    FaStrikethrough, 
    FaCode, FaListOl, 
    FaHeading, 
    FaListUl, 
    FaQuoteLeft, 
    FaUndo, 
    FaRedo,
    FaUnderline
} from "react-icons/fa";


const MenuBar = ({ editor }) => {
  if (!editor) {
    return null
  }

  const styles = {
      buttonStyle: {fontSize:"12px", margin: 7, marginRight: 10, outline: "none", background:"none", color: "rgb(70, 70, 70)", cursor:"pointer" },
      active: {background: "rgb(197, 197, 197)", padding: "2px 3px", borderRadius: 2}
  };

  const {buttonStyle, active} = styles;

  return (
    <div style={{paddingBottom: 5, display:"flex", alignItems:"center", justifyContent:"space-between"}}>
        <div style={{width: "82%"}}>
            <button style={Object.assign({}, buttonStyle, editor.isActive('bold') ? active : {})}
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={editor.isActive('bold') ? 'is-active' : ''}
            >
                <FaBold/>
            </button>
            <button style={Object.assign({}, buttonStyle, editor.isActive('italic') ? active : {})}
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={editor.isActive('italic') ? 'is-active' : ''}
            >
                <FaItalic />
            </button>
            <button  style={Object.assign({}, buttonStyle, editor.isActive('underline') ? active : {})}
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                className={editor.isActive('underline') ? 'is-active' : ''}
            >
                <FaUnderline />
            </button>
            <button style={Object.assign({}, buttonStyle, editor.isActive('strike') ? active : {})}
                onClick={() => editor.chain().focus().toggleStrike().run()}
                className={editor.isActive('strike') ? 'is-active' : ''}
            >
                <FaStrikethrough />
            </button>
            <button style={Object.assign({}, buttonStyle, editor.isActive('code') ? active : {})}
                onClick={() => editor.chain().focus().toggleCode().run()}
                className={editor.isActive('code') ? 'is-active' : ''}
            >
                <FaCode />
            </button>
            
            <button style={Object.assign({}, buttonStyle, editor.isActive('heading', { level: 6 }) ? active : {})}
                onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
                className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
            >
                <FaHeading />
            </button>

            <button style={Object.assign({}, buttonStyle, {fontSize: "14px"}, editor.isActive('heading', { level: 5 }) ? active : {})}
                onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
                className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
            >
                <FaHeading />
            </button>

            <button style={Object.assign({}, buttonStyle, {fontSize: "16px"}, editor.isActive('heading', { level: 4 }) ? active : {})}
                onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
                className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
            >
                <FaHeading />
            </button>

            <button style={Object.assign({}, buttonStyle, {fontSize: "18px"}, editor.isActive('heading', { level: 3 }) ? active : {})}
                onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
            >
                <FaHeading />
            </button>

            <button style={Object.assign({}, buttonStyle, {fontSize: "20px"}, editor.isActive('heading', { level: 2 }) ? active : {})}
                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
            >
                <FaHeading />
            </button>

            <button style={Object.assign({}, buttonStyle, {fontSize: "22px"}, editor.isActive('heading', { level: 1 }) ? active : {})}
                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
            >
                <FaHeading />
            </button>
            
            <button style={Object.assign({}, buttonStyle, editor.isActive('bulletList') ? active : {})}
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={editor.isActive('bulletList') ? 'is-active' : ''}
            >
                <FaListUl />
            </button>
            <button style={Object.assign({}, buttonStyle, editor.isActive('orderedList') ? active : {})}
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={editor.isActive('orderedList') ? 'is-active' : ''}
            >
                <FaListOl />
            </button>
            <button style={Object.assign({}, buttonStyle, editor.isActive('blockquote') ? active : {})}
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={editor.isActive('blockquote') ? 'is-active' : ''}
            >
                <FaQuoteLeft />
            </button>
        </div>
        <div style={{width: "17%"}}>
            <button style={buttonStyle} onClick={() => editor.chain().focus().undo().run()}>
                <FaUndo />
            </button>
            <button style={buttonStyle} onClick={() => editor.chain().focus().redo().run()}>
                <FaRedo />
            </button>
        </div>
    </div>
  )
}

export default (props) => {

    const { onChange, value } = props;


  const editor = useEditor({
    extensions: [
      StarterKit, Underline
    ],
    content: value,
    onUpdate({ editor }) {
        onChange(editor.getHTML());
        // console.log(editor.getHTML());
        //setEditorContent(editor.getHTML());
    },
  })

  return (
    <div className="text-editor" style={{width: "100%", fontFamily: "sans-serif", margin: "1rem auto"}}>
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
    </div>
  )
}