import React, { useContext, Suspense, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthContext from "./context/AuthProvider";
import Login from "./pages/Login";
import LoadingSpinner from "./components/LoadingSpinner";
import "./App.css";
import Add_posts from "./pages/Add_posts";
import Edit_post from "./pages/Edit_post";
import Post from "./pages/Post";
import Tags from "./pages/Tags";
import Profile from "./pages/Profile";

import Editor_example from "./pages/Tiptap";

import { useDispatch, useSelector } from "react-redux";
import { postActions } from "./store/postsSlice";
import postApi from "./api/post";
import Add_Profile from "./pages/Add_Profile";
import { toast } from "react-toastify";

const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Posts = React.lazy(() => import("./pages/Posts"));
const Settings = React.lazy(() => import("./components/Settings"));
const Index = React.lazy(() => import("./pages/Index"));
const Users = React.lazy(() => import("./pages/ViewUsers"));
const Change_Password = React.lazy(() => import("./pages/Change_Password"));

function App() {
  const user = useSelector((state) => state.userDisplay.user);
  const localUser = localStorage.getItem("user");
  const authCtx = useContext(AuthContext);
  const accessToken = authCtx.token;
  const domain = authCtx.domain;
  const dispatch = useDispatch();

  const getAllPosts = async () => {
    if(domain) {
        const response = await postApi.getAllPosts(domain, accessToken);

        if (!response.ok) return toast.error(response.originalError.message);

        dispatch(postActions.initializePosts(response.data.data));
    }
    // setPosts(response.data.data);
  };

  useEffect(() => {
    getAllPosts();
  }, []);

  console.log("localUser", authCtx.user);

  return (
    <Suspense
      fallback={
        <div className="centered">
          <LoadingSpinner />
        </div>
      }
    >
      <Routes>
        <Route path="/login" element={<Login />} />

        {authCtx.isLoggedIn && authCtx.user ? (
          <>
              <Route path="/editor" element={<Editor_example />} />
            <Route path="/" element={<Index />}>
                <Route index element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/users/" element={<Users />} />
                <Route path="/users/view_users" element={<Users />} />
                <Route path="/posts" element={<Posts />} />
                <Route path="/posts/add" element={<Add_posts />} />
                <Route path="/edit_post/:postId" element={<Edit_post />} />
                <Route path="/post/:postId" element={<Post />} />
                <Route path="/tags" element={<Tags />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/change_password" element={<Change_Password />} />
            </Route>
            {/* <Route
              path="/"
              element={<Navigate to="/dashboard" replace />}
            /> */}
            <Route path="*" element={<Index />} />
          </>
        ) : authCtx.isLoggedIn && !authCtx.user ? (
          <Route path="/" element={<Index />}>
            <Route path="/add_profile" element={<Add_Profile />} />
            <Route path="/*" element={<Add_Profile />} />
          </Route>
        ) : (
          <>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Login />} />
          </>
        )}
      </Routes>
    </Suspense>
  );
}

export default App;
