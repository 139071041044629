import Tiptap from "../components/Tiptap";

export default () => {

  return (
    <Tiptap />
  )
}

//export default Tiptap
