import React, {useContext, useState} from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import AuthContext from "../context/AuthProvider";
import { Request } from "../api";

const PostsSection = (props) => {
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    const { posts, visibility, publish } = props

    const renderPosts = () => {
        return posts.map((post, index)=> {
            return (
                <tr key={post.id} style={{paddingTop:"30px"}}>
                    <td style={{textAlign:"center"}}>{post.title}</td>
                    <td style={{textAlign:"center"}}>{post.created_at}</td>
                    <td style={{textAlign:"center"}}>
                        <button className="btn btn-primary" onClick={() => navigate(`/post/${post.id}`)}>View</button>
                        <button className="btn btn-warning" onClick={() => navigate(`/edit_post/${post.id}`, {state: { ...post },})}>
                            Edit
                        </button>
                        {(post.visible && post.published) && (
                            <button className="btn btn-danger" onClick={() => visibility(post.id)}>Hide</button>
                        ) }
                        {!post.published && (
                            <button className="btn btn-success" onClick={() => publish(post.id)}>Publish</button>
                        ) }
                        {!post.visible && (
                            <button className="btn btn-success" onClick={() => visibility(post.id)}>Make Visible</button>
                        ) }
                    </td>
                </tr>
            );
        })
    }

  const renderContent = () => {
      if(posts.length > 0) {
            return (
                <table style={{width: "100%", borderCollapse:"separate", borderspacing: "0 15em"}} spacing="2" >
                    <thead>
                        <tr>
                            <th style={{textAlign:"center"}}>Title</th>
                            <th style={{textAlign:"center"}}>Created At</th>
                            <th style={{textAlign:"center"}}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderPosts()}
                    </tbody>
                </table>
            );
      }else{
          return (
              <p style={{marginLeft: "30%", marginTop: "5em"}}>No Posts</p>
          );
      }
  }

    return (
        <>
            {renderContent()}
        </>
    );
};

export default PostsSection;
