import { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthProvider";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import { Request } from "../api";
import postApi from "../api/post";
import { postActions } from "../store/postsSlice";
import { ToastContainer, toast } from "react-toastify";
import styles from "./css/Add_post.module.css";
import { Capitalize } from "../utils/helpers";
import PostComponent from "../components/Post";
import Add_tag from "../components/Add_tag";
import TiptapEditor from "../components/Tiptap";

const limit = 25;

const Edit_post = () => {
    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);
    const dispatch = useDispatch();
    const [state, setState] = useState({
                                            loaded: false, post: '', error: [], tagError: '', isUploading: false, isCreatingTag: false, tags: [], modalIsShown: false, 
                                            selected_tag: [], InputKey: '', preview: '', title: '', coverImage: {}, content: '', isSubmiting: false
                                        })
    const { postId } = useParams();

    useEffect(() => {
        getPost();
        //getTags();
    }, []);

    const token = authCtx.token;
    const domain = authCtx.domain;
    const auth = { token, domain};


    const getPost = async () => {
        const res = await Request('GET', '/post/'+postId, auth);
        if(res.status==200) {
            const response = await postApi.getTags(domain, token);
            let tagError = '';
            let allTags = {};
            if (!response.ok) {
                tagError =  response.data.error;
            }else{
                allTags = response.data.data;
            }
            const {title, preview, coverImage, content, tags} = res.data;
            setState({...state, loaded: true, post: res.data, preview, title, coverImage, content, selected_tag: tags, tags: allTags, tagError: tagError});
        }else{
            if(res.status==401) authCtx.logout(); navigate('/login');
            setState({...state, loaded: true, error: [res.message]})
        }
    }

    // const getTags = async () => {
    //     const response = await postApi.getTags(domain, token);
    //     // console.log('getTags: ', response);
    //     if (!response.ok) return setState({...state, tagError: response.data.error});
    //     const tags = response.data.data;
    //     setState({...state, tags: tags});
    // };

    const handlePreview = (text) => {
        let words = state.preview.split(" ").filter(Boolean);

        if (words.length > limit) {
            console.log(words);
            setState({...state, preview: words.slice(0, limit).join(" ").toString().trim()});
        } else {
            setState({...state, preview: text.target.value});
        }
    };

    const OnTitleChange = (text) => {
        setState({...state, title: text.target.value});
    }

    const OnBodyChange = (val) => {
        //console.log('body: ', val);
        setState({...state, content: val});
    }

    const handleCheck = (tag) => {
        const isChecked = state.selected_tag.find((item) => item.id === tag.id);

        if (!isChecked) {
            console.log('checked');
            state.selected_tag.push(tag);
            setState({...state, selected_tag: state.selected_tag});
        } else {
            console.log('unchecked');
            const update = state.selected_tag.filter((item) => item.id !== tag.id);
            setState({...state, selected_tag: update});
        }
    };

    const handleUpload = async (image) => {
        setState({...state, isUploading: true});
        let image_to_upload = new FormData();

        image_to_upload.append("image", image);

        // console.log("image to upload", image_to_upload);

        // return;
        let accessToken = token;
        const response = await postApi.uploadImage(domain, accessToken, image_to_upload);
        if (!response.ok) {
            setState({...state, isUploading: false});
            return toast.error(response.data.errors.title[0]);
        }

        setState({...state, isUploading: false, coverImage: response.data.data});
    };

    const handleSubmit = async (event) => {
        setState({...state, isSubmiting: true});
        event.preventDefault();
        const tags = [];
        state.selected_tag.map((tag) => tags.push(tag.id));
        let {title, coverImage, preview, content} = state;
        const post = {post_id: postId, title, cover_photo_id: coverImage?.id, tags_id: tags, preview, content};

        // return console.log(post);

        console.log('submit post', post);

        const response = await postApi.updatePost(domain, token, post);
        setState({...state, isSubmiting: false});
        if (!response.ok) {
            return toast.error(response.data.errors.title[0]);
        }

        dispatch(postActions.updatePost(response.data.data));


        toast.success("Updated");
        navigate('/post/'+postId);
    };

    const show_add_tag = (event) => {
        event.preventDefault();
        setState({...state, modalIsShown: true});
    };

    const hide_add_tag = () => {
        setState({...state, modalIsShown: false});
    };

    const handleCreateTag = async (tag) => {
        setState({...state, isCreatingTag: true});
        const response = await postApi.saveTag(domain, token, tag);
        if (!response.ok) {
            setState({...state, tagError: response.data.error});
            return toast.error(response.data.errors.title[0]);
        }
        const createdTag = response.data.data;
        //console.log('create tag', tags);
        state.tags.push(createdTag);
        setState({...state, tags:state.tags, isCreatingTag: false});
        hide_add_tag();
    };

    const CreateTagModal = () => {
        if(state.modalIsShown) {
            return (
                <Add_tag onCloseModal={hide_add_tag} onTagCreate={handleCreateTag} isCreatingTag={state.isCreatingTag} />
            );
        }
    }

    const removeCoverImage = () => {
        if(window.confirm("You are about to remove the cover image! go ahead?")) {
            setState({...state, coverImage:{}});
        }
    }

    const renderTags = () => {
        if(state.tags.length > 0) {
            return state.tags.map((tag) => {
                return (
                    <div key={tag.id}>
                        <label htmlFor={tag.id}>{tag.name}</label>
                        <input
                            checked={ state.selected_tag.find((item) => item.id === tag.id) ? true : false }
                            type="checkbox"
                            id={tag.id}
                            value={tag.id}
                            onChange={() => handleCheck(tag)}
                        />
                    </div>
                );
            });
        }
    }


    const renderCoverImage = () => {
        return (Object.values(state.coverImage).length === 0) ? 
            (   <>
                    <input type="file" id="cover" onChange={(e) => handleUpload(e.target.files[0])} />
                </>
            ) 
            : 
            (
                <>
                    <a href={state.coverImage.url} target="_blank"> {state.coverImage.original_filename}</a>
                    <button onClick={removeCoverImage}>Remove</button>
                </>
            )
    }

    const renderForm = () => {
        if(!state.loaded) {
            return (<p>LOADING...</p>);
        }else{
            if(state.error != '') {
                return (<p className="alert alert-danger">{state.error}</p>);
            }else{
                return (
                        <form onSubmit={handleSubmit}>
                            <div style={elementStyles}>
                                <label htmlFor="title">TITLE: </label><br/>
                                <input value={state.title} type="text" id="title" placeholder="POST TITLE" onChange={ OnTitleChange } />
                            </div>
                            {/* <Link to={{ pathname: savedImage.url }} target="_blank">
                                {savedImage.original_filename}
                            </Link> */}
                            <div style={elementStyles}>
                                <label htmlFor="cover">COVER IMAGE: </label><br/>
                                {renderCoverImage()}
                                {state.isUploading && <h5>Uploading</h5>}
                            </div>

                            <div style={elementStyles}>
                                <label htmlFor="tags">TAGS: </label><br/>
                                Can't find your tag? <button onClick={show_add_tag.bind(this)}>Add Tag</button>
                                <div className={styles.tags}>
                                    {renderTags()}
                                </div>
                                {CreateTagModal()}
                            </div>

                            <div style={elementStyles}>
                                <label htmlFor="cover">PREVIEW: </label><br/>
                                <textarea value={state.preview} style={{width: "80%"}} placeholder="Post Preview" id="preview" onChange={handlePreview} />
                                {`${state.preview.split(" ").length - 1} / ${limit} ${
                                    state.preview.split(" ").length - 1 < 2 ? "word" : "words"
                                }`}
                            </div>

                            <div style={elementStyles}>
                                <label htmlFor="body">Post: </label><br/>
                                <TiptapEditor onChange={OnBodyChange} value={state.content} />
                            </div>

                            <div>
                                {state.isSubmiting && (
                                    <button className="btn btn-primary" disabled={true}>
                                        <i className={`fa fa-circle-o-notch fa-spin`}></i>
                                        Submiting
                                    </button>
                                )}
                                {!state.isSubmiting && <button className="btn btn-primary">Submit</button>}
                            </div>
                        </form>
                );
            }
        }
    }

    const displayErrors = () => {
        if(state.error.length > 0) {
            setTimeout(() => setState({...state, error:[]}), 5000);
            return state.error.map((error, key) => {
                return (<div key={key} className="alert alert-danger"><span>{error}</span><br/></div>); 
            })
        }
    }

    const myStyles = {
        elementStyles: {marginTop: "1em", marginBottom:"1em", marginRight:"auto", marginLeft:"auto", width: "95%"}
    };
    
    const { elementStyles } = myStyles;

  return (
    // <>
    //     {renderContent()}
    // </>
    <div>
        <Link to={`/post/${postId}`} style={{float: "right", marginRight: "10%"}}>
            <h3>Preview</h3>
        </Link>
        <h1 style={{paddingLeft: "2%"}}>Edit Post</h1>
          {renderForm()}
    </div>
  );
};

export default Edit_post;
