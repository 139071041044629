import React, {useContext} from "react";
import AuthContext from "../context/AuthProvider";

const Profile = () => {
    const authCtx = useContext(AuthContext);
    console.log('user: ', authCtx);

    const handleEdit = (event) => {
        event.preventDefault();
        console.log('edit');
    }
  return (
    <div style={{display:"flex", flexDirection:"column"}}>
        <div style={{display:"flex", flexDirection:"row", marginBottom:"2em"}}>
            <h2 style={{marginRight:"3%"}}>PROFILE</h2>
            <button className="btn btn-warning" onClick={handleEdit}>Edit</button>
        </div>
        <div>
            <h4>Name: </h4>
            <span>{authCtx.user}</span>
            <hr/>
        </div>
        <div>
            <h4>Blog Name: </h4>
            <span>{authCtx.blog_name}</span>
            <hr/>
        </div>
        <div>
            <h4>About: </h4>
            <span>{authCtx.about}</span>
            <hr/>
        </div>
    </div>
  );
};

export default Profile;
