import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import styles from "./css/Add_post.module.css";
import AuthContext from "../context/AuthProvider";
import postApi from "../api/post";
import Add_tag from "../components/Add_tag";
import { toast } from "react-toastify";
import { postActions } from "../store/postsSlice";
import TiptapEditor from "../components/Tiptap";

const limit = 25;

function Add_posts(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [post_title, setPost_title] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [savedImage, setSavedImage] = useState();
    const [isCreatingTag, setIsCreatingTag] = useState(false);

    const [tags, setTags] = useState();
    const [preview, setPreview] = useState("");
    const [post_body, setPost_body] = useState("");

    const [modalIsShown, setModalIsShown] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const authCtx = useContext(AuthContext);
    const accessToken = authCtx.token;
    const domain = authCtx.domain;
    const [selected_tag, setSelected_tag] = useState([]);
    const [InputKey, setInputKey] = useState();

    const resetFields = () => {
      setPost_title("");
      setSavedImage("");
      setPreview("");
      setPost_body("");
      setSelected_tag([]);
      resetsFileInput();
    };

    const resetsFileInput = () => {
        let randomString = Math.random().toString(36);
        setInputKey(randomString);
    };

    const handleCheck = (tag) => {
        const isChecked = selected_tag.find((item) => item.id === tag.id);

        if (!isChecked) {
            setSelected_tag([...selected_tag, tag]);
        } else {
            const update = selected_tag.filter((item) => item.id !== tag.id);
            setSelected_tag(update);
        }
    };

    const show_add_tag = (event) => {
        event.preventDefault();
        setModalIsShown(true);
    };

    const hide_add_tag = () => {
        setModalIsShown(false);
    };

    const getTags = async () => {
        const response = await postApi.getTags(domain, accessToken);
        if (!response.ok) return setErrorMsg(response.data.error);

        setTags(response.data.data);
    };

    const handlePreview = (text) => {
        let words = preview.split(" ").filter(Boolean);

        if (words.length > limit) {
            console.log(words);
            setPreview(words.slice(0, limit).join(" ").toString().trim());
        } else {
            setPreview(text.target.value);
        }
    };

    const handleCreateTag = async (tag) => {
        setIsCreatingTag(true);
        const response = await postApi.saveTag(domain, accessToken, tag);
        if (!response.ok) {
            setErrorMsg(response.data.error);
            return toast.error(response.data.errors.title[0]);
        }

        setTags([...tags, response.data.data]);
        setIsCreatingTag(false);
        hide_add_tag();
    };

    const handleUpload = async (image) => {
        setIsUploading(true);
        let image_to_upload = new FormData();

        image_to_upload.append("image", image);

        //console.log("image to upload", image_to_upload);
        const response = await postApi.uploadImage(
          domain,
          accessToken,
          image_to_upload
        );
        if (!response.ok) {
            setErrorMsg(response.data.error);
            return toast.error(response.data.errors.title[0]);
        }
        setIsUploading(false);

        setSavedImage(response.data.data);
    };

    useEffect(() => {
        getTags();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const tags = [];
        selected_tag.map((tag) => tags.push(tag.id));
        const post = {
            title: post_title,
            cover_photo: savedImage?.id,
            tags_id: tags,
            preview,
            content: post_body,
        };

        const response = await postApi.savePost(domain, accessToken, post);

        if (!response.ok) return toast.error(response.data.errors.title[0]);

        dispatch(postActions.addPost(response.data.data));

        resetFields();

        toast.success("Posted");
        navigate('/posts');
    };

    const OnBodyChange = (val) => {
        console.log('body: ', val);
        setPost_body(val);
    }

    const renderTags = () => {
        if(tags) {
            return tags.map((tag) => {
                return (
                    <div key={tag.id}>
                      <label htmlFor={tag.id}>{tag.name}</label>
                      <input
                          checked={ selected_tag.find((item) => item.id === tag.id) ? true : false }
                          type="checkbox"
                          id={tag.id}
                          value={tag.id}
                          onChange={() => handleCheck(tag)}
                      />
                    </div>
                );
            });
        }
    }

    const CreateTagModal = () => {
        if(modalIsShown) {
            return (
                <Add_tag onCloseModal={hide_add_tag} onTagCreate={handleCreateTag} isCreatingTag={isCreatingTag} />
            );
        }
    }

    const myStyles = {
        elementStyles: {marginTop: "2em", marginBottom:"2em"}
    };

    const { elementStyles } = myStyles;


    return (
        <div style={{width: "100%"}}>
            <ToastContainer />

            <h1 style={{paddingLeft: "2%"}}>Add Post</h1>
            <form style={{width:"95%", marginRight:"auto", marginLeft:"auto"}}>
                <div style={elementStyles}>
                    <label htmlFor="title">TITLE: </label><br/>
                    <input value={post_title} type="text" id="title" placeholder="POST TITLE" onChange={(text) => setPost_title(text.target.value)} />
                </div>

                <div style={elementStyles}>
                    <label htmlFor="cover">COVER IMAGE: </label><br/>
                    <input key={InputKey} type="file" id="cover" onChange={(e) => handleUpload(e.target.files[0])} />
                    {isUploading && <h5>Uploading</h5>}
                </div>

                <div style={elementStyles}>
                    <label htmlFor="tags">TAGS: </label><br/>
                    Can't find your tag? <button onClick={show_add_tag.bind(this)}>Add Tag</button>
                    <div className={styles.tags}>
                        {renderTags()}
                    </div>

                    {CreateTagModal()}
                </div>

                <div style={elementStyles}>
                    <label htmlFor="cover">PREVIEW: </label><br/>
                    <textarea value={preview} style={{width: "80%"}} placeholder="Post Preview" id="preview" onChange={handlePreview} />
                    {`${preview.split(" ").length - 1} / ${limit} ${
                      preview.split(" ").length - 1 < 2 ? "word" : "words"
                    }`}
                </div>

                <div styles={{height:"20em"}}>
                    <label htmlFor="body">Post: </label><br/>
                    <TiptapEditor onChange={OnBodyChange} />
                </div>

                <div className={styles.submit_button}>
                  <button className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                </div>
            </form>
        </div>
  );
}

export default Add_posts;
