import { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthProvider";
import styles from "./css/Dashboard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, NavLink, useNavigate } from "react-router-dom";
import PostsSection from "../components/PostsSection";
import { Request } from "../api";
import postApi from "../api/post";
import Add_tag from "../components/Add_tag";

const Tags = () => {
    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);
    const [state, setState] = useState({
                                            tags: '', error: '', successMsg: '', loaded: false, edit: false, currentTagId: '', tagName: '',  
                                            isCreatingTag: false, isEditing: false, isDeleting: false, modalIsShown: false,
                                        })
    const isLoading = useSelector((state) => state.userDisplay.isLoading);
    const dispatch = useDispatch();

    const token = authCtx.token;
    const domain = authCtx.domain;
    const auth = { token, domain};
    useEffect(() => {
        getTags();
    }, []);

    const getTags = async () => {
        const res = await Request('GET', '/tag/all', auth);
        if(res.status==200) {
            setState({...state, loaded: true, tags: res.data})
        }else{
            if(res.status==401) authCtx.logout(); navigate('/login');
            setState({...state, loaded: true, error: res.message})
        }
    }   

    const show_add_tag = (event) => {
        event.preventDefault();
        setState({...state, modalIsShown: true});
    };

    const hide_add_tag = () => {
        setState({...state, modalIsShown: false});
    };

    const handleCreateTag = async (tag) => {
        setState({...state, isCreatingTag: true});
        const response = await postApi.saveTag(domain, token, tag);
        if (!response.ok) return setState({...state, tagError: response.data.error});
        const createdTag = response.data.data;
        //console.log('create tag', tags);
        state.tags.push(createdTag);
        setState({...state, tags:state.tags, isCreatingTag: false});
        hide_add_tag();
    };

    const onTagChange = (text) => {
        console.log(text.target.value);
        setState({...state, tagName: text.target.value});
    }

    const handleEditTag = async () => {
        setState({...state, isEditing: true});
        let tag_id = state.currentTagId;
        let name = state.tagName;
        const response = await Request('POST', '/tag/update', auth, {tag_id, name});
        
        const msg = response.message;
        if(response.status == 200) {
            const editedTag = response.data;
            state.tags.map((tag, i) => {
                if(tag.id==editedTag.id) state.tags[i] = editedTag
            });
            console.log(state.tags);
            setState({...state, tags:state.tags, successMsg: msg, isEditing: false, edit: false, currentTagId: ''});
            // console.log('success');
            // //setState({...state, tags:state.tags, successMsg: response.message, isEditing: false, edit: false, currentTagId: ''});
            setTimeout(() => {
                setState({...state, successMsg:'', edit: false, currentTagId: ''});
            }, 5000);
        }else{
            if(response.status==401) {
                authCtx.logout(); navigate('/login');
            }
            setState({...state, error:msg, isEditing: false, edit: false, currentTagId: ''});
            setTimeout(() => {
                setState({...state, error:'', edit: false, currentTagId: ''});
            }, 5000);
        }
    };

    const handleDeleteTag = async (tagId) => {
        if(window.confirm('Are You Sure you want to delete this Tag?')) {
            setState({...state, isDeleting: true});
            const response = await Request('GET', `/tag/delete/${tagId}`, auth);
            
            const msg = response.message;
            if(response.status == 200) {
                let tags = state.tags.filter((tag, i) => (tag.id != tagId) );
                setState({...state, tags:tags, successMsg: msg, isDeleting: false});
                // console.log('success');
                // //setState({...state, tags:state.tags, successMsg: response.message, isEditing: false, edit: false, currentTagId: ''});
                setTimeout(() => {
                    setState({...state, successMsg:''});
                }, 5000);
            }else{
                if(response.status==401) {
                    authCtx.logout(); navigate('/login');
                }
                setState({...state, error:msg});
                setTimeout(() => {
                    setState({...state, error:''});
                }, 5000);
            }
        }
    };

    const CreateTagModal = () => {
        if(state.modalIsShown) {
            return (
                <Add_tag onCloseModal={hide_add_tag} onTagCreate={handleCreateTag} isCreatingTag={state.isCreatingTag} />
            );
        }
    }

    const renderTags = () => {
        return state.tags.map((tag, index)=> {
            return (
                <tr key={tag.id}>
                    <td style={{textAlign:"center"}}>
                        {(state.edit && state.currentTagId==tag.id) ? (<input type="text" id="name" value={state.tagName} onChange={onTagChange} />) : (tag.name)}
                    </td>
                    <td style={{textAlign:"center"}}>
                        {
                            (state.edit && state.currentTagId==tag.id) ?
                                (
                                    <>
                                        <button className="btn btn-success mr-5" onClick={handleEditTag} disabled={state.isEditing}>
                                            Update
                                        </button> 
                                        <button className="btn btn-secondary" onClick={() => setState({...state, edit:false, currentTagId: ''})}>
                                            Cancel
                                        </button>
                                    </>
                                )
                                :
                                (
                                    <button 
                                        className="btn btn-warning" onClick={() => setState({...state, edit:true, currentTagId: tag.id, tagName: tag.name})} 
                                        disabled={state.isDeleting}
                                    >
                                        Edit
                                    </button>
                                )
                        }
                        <button className="btn btn-danger" onClick={() => handleDeleteTag(tag.id)} disabled={state.edit || state.isDeleting}>
                            Delete
                        </button>
                    </td>
                </tr>
            );
        })
    }

    const renderContent = () => {
        if(!state.loaded) {
            return (<p>LOADED...</p>);
        }else{
            return (
                <table className="table">
                    <thead>
                        <tr>
                            <th className="text-center">NAME</th>
                            <th className="text-center">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTags()}
                    </tbody>
                </table>
            );
        }
    }

    const displaySuccessMsg = () => {
        if(state.successMsg != '') return (<p className="alert alert-success">{state.successMsg}</p>);
    }

    const displayErrorMsg = () => {
        if(state.error != '') return (<p className="alert alert-danger">{state.error}</p>);
    }

  return (
    <div className="container">
        <h1 style={{width: "100%"}}>Tags(<button className="btn btn-primary" onClick={show_add_tag.bind(this)}>+</button>)</h1>
        {CreateTagModal()}
        {displayErrorMsg()}
        {displaySuccessMsg()}
        {renderContent()}
    </div>
  );
};

export default Tags;
