import { createContext, useCallback, useEffect, useState } from "react";

let logoutTimer;

const AuthContext = createContext({
  token: "",
  domain: "",
  email: "",
  isLoggedIn: false,
  user: "",
  blog_name: "",
  about: "",
  addUser: () => {},
  login: () => {},
  logout: () => {},
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjustedExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjustedExpirationTime - currentTime;

  return remainingDuration;
};

const reteriveStoredToken = () => {
  const storedEmail = localStorage.getItem("email");
  const storedDomain = localStorage.getItem("domain");
  const storedToken = localStorage.getItem("token");
  const storedExpirationDate = localStorage.getItem("expirationTime");
  const storedUser = localStorage.getItem("user");
  const storedBlogName = localStorage.getItem("blog_name");
  const storedAbout = localStorage.getItem("about");

  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 60000) {
    localStorage.removeItem("email");
    localStorage.removeItem("domain");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("blog_name");
    localStorage.removeItem("about");
    localStorage.removeItem("expirationTime");
    return null;
  }

  return {
    email: storedEmail,
    domain: storedDomain,
    token: storedToken,
    duration: remainingTime,
    user: storedUser,
    blog_name: storedBlogName,
    about: storedAbout
  };
};

export const AuthProvider = (props) => {

    const tokenData = reteriveStoredToken();
    let initialToken;
    let initialEmail;
    let initialDomain;
    let initialUser;
    let initialBlogName;
    let initialAbout;
    if (tokenData) {
        initialToken = tokenData.token;
        initialEmail = tokenData.email;
        initialDomain = tokenData.domain;
        initialUser = tokenData.user;
        initialBlogName = tokenData.blog_name;
        initialAbout = tokenData.about;
    }
    const [token, setToken] = useState(initialToken);
    const [email, setEmail] = useState(initialEmail);
    const [domain, setDomain] = useState(initialDomain);
    const [user, setUser] = useState(initialUser);
    const [blog_name, setBlogName] = useState(initialBlogName);
    const [about, setAbout] = useState(initialAbout);

    const userIsLoggedIn = !!token;

    const handleLogout = useCallback(() => {
        setToken(null);
        setEmail("");
        setDomain("");
        setUser("");
        setBlogName("")
        setAbout("")
        localStorage.removeItem("email");
        localStorage.removeItem("domain");
        localStorage.removeItem("token");
        localStorage.removeItem("blog_name");
        localStorage.removeItem("about");
        localStorage.removeItem("expirationTime");
        localStorage.removeItem("user");

        if (logoutTimer) {
          clearTimeout(logoutTimer);
        }
    }, []);

    const handleLogin = (email, domain, token, expirationTime) => {
        setToken(token);
        setEmail(email);
        setDomain(domain);
        localStorage.setItem("email", email);
        localStorage.setItem("domain", domain);
        localStorage.setItem("token", token);
        localStorage.setItem("expirationTime", expirationTime);

        const remainingTime = calculateRemainingTime(expirationTime);

        logoutTimer = setTimeout(handleLogout, remainingTime);
    };

    const handleAddUser = (user) => {
        setUser(user.name);
        setBlogName(user.blog_name);
        setAbout(user.about);
        localStorage.setItem("user", user.name);
        localStorage.setItem("blog_name", user.blog_name);
        localStorage.setItem("about", user.about);
    };

    useEffect(() => {
      if (tokenData) {
        logoutTimer = setTimeout(handleLogout, tokenData.duration);
      }
    }, [tokenData, handleLogout]);

    const contextValue = {
      token,
      email,
      domain,
      isLoggedIn: userIsLoggedIn,
      user,
      blog_name,
      about,
      addUser: handleAddUser,
      login: handleLogin,
      logout: handleLogout,
    };

    return (
      <AuthContext.Provider value={contextValue}>
        {props.children}
      </AuthContext.Provider>
    );
};

export default AuthContext;
